<script>
  import clsx from "clsx";
  import { UserPicture } from "components";
  import styles from "styleguide/UserPictures.json";

  export let style = "";
  export let users = [];
  export let limit = 4;
  export let isAvatarClickable;

  $: usersShow = users.length > limit ? getLimitedUsers(users, limit) : users;

  function getLimitedUsers(users, limit) {
    let difference = users.length - limit;

    return [...users.slice(0, limit), { name: `+${difference}` }];
  }
</script>

<div
  data-component="UserPictures"
  class={clsx(
    styles.wrapper,
    style.split(" ").map((x) => styles[x]),
  )}
>
  {#each usersShow as { name, avatar }}
    <div class={styles.item}>
      <UserPicture {name} {avatar} {isAvatarClickable} />
    </div>
  {/each}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  .item {
    margin-left: -5px;

    .separate & {
      margin-left: 8px;
    }

    &.item:last-child {
      margin-left: 0;
    }
  }
</style>
