<script>
  import { onMount, onDestroy } from "svelte";
  import { user } from "stores/user.js";
  import { breadcrumb } from "stores/breadcrumb.js";
  import {
    getProjectsOverview,
    receiveProjects,
    checkOnPopstate,
    projectsAccesses,
  } from "stores/dashboard/project.js";
  import {
    reset,
    availableConfirmationTypes,
  } from "stores/confirmation/confirmation_new.js";
  import { updatePageHistory } from "stores/page_history.js";

  import { platformLink } from "lib/helpers.js";

  import {
    DashboardClientSidebar,
    DashboardProjectList,
    DashboardProjectListSidebar,
  } from "apps/dashboard";
  import { Boundary, ContentSidebar } from "components";

  export let initialState;

  onMount(async () => {
    getProjectsOverview();
    receiveProjects(initialState);
    availableConfirmationTypes.set(initialState.available_confirmation_types);
    projectsAccesses.set(initialState.project_accesses);
    window.addEventListener("popstate", checkOnPopstate);

    reset();

    breadcrumb.set([{ translation: "projects", path: "" }]);

    updatePageHistory([
      {
        path: platformLink("dashboard"),
        titles: [{ translation: "projects" }, { translation: "overview" }],
      },
    ]);
  });

  onDestroy(() => {
    removeEventListener("popstate", checkOnPopstate);
  });
</script>

<ContentSidebar>
  <div slot="content" data-component="DashboardIndex">
    <DashboardProjectList />
  </div>
  <div slot="sidebar" data-component="DashboardIndex">
    <Boundary>
      {#if $user.role === "client"}
        <DashboardClientSidebar />
      {:else}
        <DashboardProjectListSidebar />
      {/if}
    </Boundary>
  </div>
</ContentSidebar>
