<script>
  import { getContext } from "svelte";
  import { Modal } from "components/modals";
  import { t } from "stores/i18n.js";

  import { auditId, activeExport, auditExportsList, selectedControls } from "stores/pbc.js";
  import { can } from "stores/permission.js";
  import { isAccountFeature } from "stores/account";

  import AuditApi from "apis/pbc/audit.js";
  import {
    dateTimeFormat,
    replaceObjectInArray,
    iconPath,
    downloadFile,
    formatExpiresInDate,
  } from "lib/helpers.js";

  import { Button, Checkbox, IconButton, Tooltip } from "components";
  import styles from "styleguide/AuditExportModal.json";

  let items = [];
  let shorten_name = true;
  let include_original_files = $isAccountFeature("pdftron_annotations");
  let nested_controls = true;
  let export_comments_logs = true;
  let loadedExports = false;
  let loadingDownload = false;

  const { language } = getContext("config");

  $: limit = items.length >= 3;

  function onCreate() {
    AuditApi.createExport({
      params: {
        shorten_name,
        include_original_files,
        nested_controls,
        export_comments_logs,
        control_ids: $selectedControls,
      },
      success: setExport,
    });
  }

  function checkExport(id) {
    AuditApi.checkExport({
      params: {
        id,
      },
      success: (response) => {
        const status = response.data.export.status;

        items = items.map((item) => {
          if (item.permalink === id) {
            item.status = status;

            if (status === "processing") {
              setTimeout(() => {
                checkExport(id);
              }, 2000);
            }
          }

          return item;
        });

        if (
          status === "done" ||
          status === "cancelled" ||
          status === "failed"
        ) {
          setExport(response);
        }
      },
    });
  }

  function setExport(response) {
    const newExport = response.data.export;
    if (!newExport) return;
    const id = newExport.permalink;

    if (newExport.status === "processing") {
      setTimeout(() => {
        checkExport(id);
      }, 2000);
    }

    if (newExport.status === "done") {
      onDownload(id);
    }

    let itemsToExportsList = items.filter(
      (item) => item.status === "processing",
    );
    itemsToExportsList = replaceObjectInArray(itemsToExportsList, newExport);
    itemsToExportsList = itemsToExportsList.map((item) => {
      item.filename = newExport.filename;
      return item;
    });

    auditExportsList.set(itemsToExportsList);
    items = replaceObjectInArray(items, newExport);
    activeExport.set(false);
  }

  function onDownload(id) {
    loadingDownload = true;
    AuditApi.getExport({
      params: {
        id,
      },
      success: (file) => {
        downloadFile(file, () => {
          loadingDownload = false;
        });
      },
    });
  }

  function onDelete(id) {
    AuditApi.deleteExport({
      params: {
        id,
      },
      success: () => {
        items = items.filter((item) => item.permalink !== id);
      },
    });
  }

  function onShow() {
    if ($auditId && !loadedExports) {
      AuditApi.getExports({
        success: (response) => {
          loadedExports = true;
          items = response.data.exports;
        },
      });
    }
  }

  function onHide() {
    activeExport.set(false);
  }
</script>

{#if $activeExport}
  <Modal
    shown={$activeExport}
    {onShow}
    {onHide}
    title={$t("audit_export_modal.title")}
  >
    <div data-component="AuditExportModal">
      <div class={styles.top}>
        <div class={styles.description}>
          {@html $t("audit_export_modal.description")}
        </div>

        {#if limit}
          <div class={styles.limit}>{$t("audit_export_modal.limit")}</div>
        {/if}

        <div class={styles.checkbox}>
          <Checkbox
            bind:toggled={nested_controls}
            label={$t("audit_export_modal.nested_controls")}
          />
        </div>
        <div class={styles.checkbox}>
          <Checkbox
            bind:toggled={export_comments_logs}
            label={$t("audit_export_modal.export_comments_logs")}
          />
        </div>
        <div class={styles.checkbox}>
          <Checkbox
            bind:toggled={shorten_name}
            label={$t("audit_export_modal.shorten_name")}
          />
        </div>
        {#if $isAccountFeature("pdftron_annotations")}
          <div class={styles.checkbox}>
            <Checkbox
              bind:toggled={include_original_files}
              label={$t("audit_export_modal.include_original_files")}
            />
          </div>
        {/if}

        {#if $can("create", "audit_export")}
          <div class={styles["create-button"]}>
            <Button style={"primary"} disabled={limit} click={onCreate}>
              {$t("audit_export_modal.create")}
            </Button>
          </div>
        {/if}
      </div>
      <div class={styles.bottom}>
        <div class={styles.previous}>{$t("audit_export_modal.previous")}</div>

        {#each items as { created_at, created_by_name, permalink, status, filesize, expires_in }}
          <div class={styles.item}>
            <div class={styles.details}>
              <Tooltip>
                <div slot="tooltip">{$t(`audit_export_modal.${status}`)}</div>
                <div
                  slot="content"
                  class="{styles.status} {styles[status]}"
                ></div>
              </Tooltip>
              <img
                class={styles.icon}
                src={iconPath("zip-stroke")}
                alt={"zip-stroke"}
              />

              <div class={styles.info}>
                <div>
                  <span class={styles.created}
                    >{$t("audit_export_modal.export_by")}</span
                  > <span class={styles.name}>{created_by_name}</span>
                </div>
                <div class={styles.meta}>
                  {dateTimeFormat(created_at)}
                  {#if filesize}({filesize} MB){/if}
                  {" - "}{formatExpiresInDate(expires_in, language)}
                </div>
              </div>
            </div>

            <div class={styles.buttons}>
              {#if status === "done"}
                {#if $can("read", "audit_export")}
                  <IconButton
                    loading={loadingDownload}
                    style={"primary-text"}
                    icon="download"
                    click={() => onDownload(permalink)}
                  />
                {/if}
              {/if}
              {#if $can("destroy", "audit_export")}
                <IconButton
                  style={"error-text"}
                  icon="trash"
                  click={() => onDelete(permalink)}
                />
              {/if}
            </div>
          </div>
        {:else}
          {#if loadedExports}
            <div class={styles.noyet}>{$t("audit_export_modal.no_yet")}</div>
          {:else}
            <div class={styles.noyet}>
              {$t("audit_export_modal.files_loading")}
            </div>
          {/if}
        {/each}
      </div>
    </div>
  </Modal>
{/if}

<style lang="scss">
  .bottom {
    padding: 24px 0;
  }

  .description {
    margin-bottom: 24px;
  }

  .limit {
    color: var(--red-200);
    margin-bottom: 15px;
  }

  .previous {
    font-weight: 600;
    margin-bottom: 20px;
  }

  .empty {
    font-size: 12px;
    text-align: center;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .buttons {
    display: flex;
  }

  .details {
    display: flex;
    align-items: center;
  }

  .status {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.done {
      background-color: var(--green);
    }

    &.processing {
      background-color: var(--yellow);
    }

    &.cancelled {
      background-color: var(--red-100);
    }

    &.failed {
      background-color: var(--red-100);
    }
  }

  .icon,
  .info {
    margin-left: 10px;
  }

  .name {
    font-weight: 500;
  }

  .meta {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
  }

  .created {
    color: var(--primary-300);
  }

  .checkbox {
    margin-bottom: 16px;
  }

  .create-button {
    margin-top: 24px;
  }
</style>
