import { writable, derived } from "svelte/store";

class Document {
  constructor(params = {}) {
    this.url = params.url;
    this.id = params.id;
    this.title = params.title;
    this.is_read = params.is_read;
    this.is_archived = params.is_archived;
    this.page = params.page || 1;
    this.watermarks = params.watermarks;
  }
}

class DocumentViewerStore {
  constructor() {
    const initialStoreValues = {
      // To determine which lincense needs to be added.
      isPBC: writable(false),

      // Feature Flag for Annotations
      isAnnotationsEnabled: writable(false),

      // Used to generate previews within the hidden hiddenWebViewerInstance.
      thumbnails: writable([]),

      // Stores the current opened document in the webviewer
      document: writable(new Document()),
    };

    // Assign the properties from initialStoreValues and create computed properties for each store property
    for (const key in initialStoreValues) {
      this[key] = initialStoreValues[key];
      this[`${key}Readable`] = derived(this[key], ($value) => $value);
    }
  }

  /**
   * Dynamically updates multiple properties of an object with the values provided.
   *
   * @param {Object} values - An object containing key-value pairs to update the properties.
   */
  update(values) {
    Object.keys(values).forEach((key) => {
      if (this[key] && typeof this[key].set === "function") {
        this[key].set(values[key]);
      }
    });
  }

  /**
   * Enqueues PDF URLs for generating document thumbnails or previews in a sequential order.
   * If an item with the same ID already exists, it is replaced by the new item.
   *
   * @param {string} id - The unique identifier for the document.
   * @param {string} url - The URL of the PDF document.
   * @param {Array} thumbnails - An array to store document thumbnails (optional, defaults to an empty array).
   */
  generateThumbnails(id, url, thumbnails = [], watermarks = false) {
    this.thumbnails.update((queue) => {
      // Filter out any existing item with the same id (in case of switching the channel of the mailing)
      const filteredQueue = queue.filter((item) => item.id !== id);

      // Add new item to the queque
      return [
        ...filteredQueue,
        { id: id, url: url, thumbnails: thumbnails, watermarks, pageCount: 0 },
      ];
    });
  }

  /**
   * Updates the page count for document thumbnails in the queue.
   *
   * @param {string} id - The unique identifier of the document.
   * @param {number} pageCount - The new page count to set for the document.
   */
  updatePageCountForThumbnails(id, pageCount) {
    this.thumbnails.update((data) => {
      return data.map((item) => {
        if (item.id === id) {
          return { ...item, pageCount: pageCount };
        }
        return item;
      });
    });
  }

  /**
   * Adds a generated thumbnails to the document in the queue.
   *
   * @param {string} id - The unique identifier of the document.
   * @param {Array} thumbnails - The thumbnails to add to the document.
   */
  addGeneratedThumbnails(id, thumbnails) {
    this.thumbnails.update((data) => {
      return data.map((item) => {
        if (item.id === id) {
          item.thumbnails.push(...thumbnails);
        }
        return item;
      });
    });
  }

  /**
   * Open a document.
   *
   * @param {Object} params - Parameters for the document.
   * @param {string} params.url - The URL of the document.
   * @param {string} params.id - The ID of the document.
   * @param {string} params.title - The title of the document.
   * @param {boolean} params.is_read - Whether the document is read.
   * @param {boolean} params.is_archived - Whether the document is archived.
   * @param {number} [params.page=1] - The page number of the document.
   * @param {boolean} [params.watermarks] - Add watermarks to the document.
   */
  openDocument(params) {
    this.document.set(new Document(params));
  }

  /**
   * Closes the currently open document by resetting it.
   */
  closeDocument() {
    this.document.set(new Document({}));
  }
}

const documentViewerStore = new DocumentViewerStore();

export default documentViewerStore;
