import { Controller } from "stimulus";
import Api from "../../general/api";
import Helper from "../../general/helper";

export default class extends Controller {
  static targets = [
    "quickSelect",
    "actions",
    "selectedCount",
    "bottomBarForm",
    "debtorProgressRing",
    "creditorProgressRing",
  ];

  connect() {
    this.checkedState = false;
    setTimeout(() => {
      document
        .querySelectorAll("[data-sample-item-checkbox]:checked:not(:disabled)")
        .forEach((el) => {
          el.checked = false;
        }),
        100;
    }); // requires timeout to actually see checkboxes in DOM
  }

  toggleCheckboxEvent(e) {
    e.preventDefault();

    let target = e.target.closest(".sample-list--cell").querySelector("input"),
      previousCheckedState = target.checked;
    if (target.disabled) return;

    target.checked = !target.checked;
    this.toggleCheckbox(target);
    this.setProgressRing(target, previousCheckedState);
  }

  updateSelectedValue(ringNode, sum, operation) {
    let currentSum = parseInt(
      ringNode.getAttribute("data-progress-ring-progress"),
    );
    ringNode.setAttribute(
      "data-progress-ring-progress",
      operation(currentSum, sum),
    );
    this.application
      .getControllerForElementAndIdentifier(ringNode, "progress-ring")
      .connect();
  }

  toggleCheckbox(target) {
    this.checkboxCountChange(target);
    this.toggleCheckedState();
  }

  deselect(e) {
    this.uncheckCheckboxes();
  }

  checkboxCountChange(element) {
    let selectedCount = this.selectedCountTarget.textContent;
    if (element.checked) {
      this.selectedCountTarget.innerHTML = parseInt(selectedCount) + 1;
    } else {
      this.selectedCountTarget.innerHTML = parseInt(selectedCount) - 1;
    }
  }

  uncheckCheckboxes() {
    let self = this;
    document
      .querySelectorAll("[data-sample-item-checkbox]:checked:not(:disabled)")
      .forEach((el) => {
        el.checked = false;
        self.setProgressRing(el);
      });
    document
      .querySelectorAll("[data-sample-header-checkbox]:checked:not(:disabled)")
      .forEach((el) => {
        el.checked = false;
      });
    this.selectedCountTarget.innerText = 0;
    this.toggleCheckedState();
  }

  toggleAllCheckboxes(e) {
    e.preventDefault();
    let self = this,
      target = e.target.closest(".sample-list--cell").querySelector("input");
    target.checked = !target.checked;

    document
      .querySelectorAll("[data-sample-item-checkbox]:not(:disabled)")
      .forEach((el) => {
        let previousCheckedState = el.checked;
        el.checked = target.checked;
        self.setProgressRing(el, previousCheckedState);
      });
    this.setAllCheckboxCount();
    this.toggleCheckedState();
  }

  toggleCheckedState() {
    const quickSelectElement = document.querySelector(
      `div[data-samples-list-target="quickSelect"]`,
    );

    let checkedCheckboxes = document.querySelectorAll(
      "[data-sample-item-checkbox]:checked:not(:disabled)",
    );
    this.checkedState = checkedCheckboxes.length > 0;

    if (quickSelectElement === null) return;

    if (this.checkedState) {
      this.quickSelectTarget.classList.add("hidden");
      this.actionsTarget.classList.remove("hidden");
    } else {
      this.quickSelectTarget.classList.remove("hidden");
      this.actionsTarget.classList.add("hidden");
    }
  }

  sendViaEmail(e) {
    e.preventDefault();
    this.submitSamples("email");
  }

  sendViaPostal(e) {
    e.preventDefault();
    this.submitSamples("postal");
  }

  cancelSelected(e) {
    e.preventDefault();
    this.submitSamples("cancel");
  }

  submitSamples(button) {
    let buttonElement = document.createElement("input");
    buttonElement.type = "hidden";
    buttonElement.name = "button";
    buttonElement.value = button;

    let samplesElement = document.createElement("input");
    samplesElement.type = "hidden";
    samplesElement.name = "selected_samples";
    samplesElement.value = Array.from(
      document.querySelectorAll(
        "[data-sample-item-checkbox]:checked:not(:disabled)",
      ),
    )
      .map((el) => {
        return el.getAttribute("data-id");
      })
      .join(",");

    this.bottomBarFormTarget.appendChild(buttonElement);
    this.bottomBarFormTarget.appendChild(samplesElement);
    this.bottomBarFormTarget.submit();
  }

  redirectToSample(e) {
    let checkboxTarget = e.target.closest(".sample-list--checkbox-cell"),
      itemTarget = e.target.closest(".samples-list--item");
    if (!checkboxTarget) {
      window.location = itemTarget.getAttribute("data-url");
    }
  }

  setAllCheckboxCount() {
    const selectedCountElement = document.querySelector(
      `span[data-target="samples-list.selectedCount"]`,
    );

    if (selectedCountElement) {
      this.selectedCountTarget.textContent = document.querySelectorAll(
        "[data-sample-item-checkbox]:checked:not(:disabled)",
      ).length;
    }
  }

  setProgressRing(target, previousCheckedState) {
    if (
      previousCheckedState === target.checked ||
      !this.hasDebtorProgressRingTarget
    )
      return;

    let op = target.checked ? Helper.plus : Helper.minus,
      item = target.closest(".samples-list--item"),
      sampleTypeCell = item.querySelector(".sample-list--type-cell"),
      sampleSum = Math.abs(
        parseFloat(
          item
            .querySelector("[data-sum]")
            .getAttribute("data-sum")
            .replace(/,/, "."),
        ) * 100, // into cents
      );

    if (sampleTypeCell.getAttribute("data-sample-type") === "debitor") {
      this.updateSelectedValue(
        this.debtorProgressRingTarget.querySelector(
          `[data-controller="progress-ring"]`,
        ),
        sampleSum,
        op,
      );
    } else if (sampleTypeCell.getAttribute("data-sample-type") === "creditor") {
      this.updateSelectedValue(
        this.creditorProgressRingTarget.querySelector(
          `[data-controller="progress-ring"]`,
        ),
        sampleSum,
        op,
      );
    }
  }
}
