<script>
  import styles from "styleguide/ConfirmationNewTypeItem.json";

  import { t } from "stores/i18n.js";
  import { types, client } from "stores/confirmation/confirmation_new.js";

  import ClientUsersApi from "apis/users/client_users.js";
  import { iconPath } from "lib/helpers";
  import { validate } from "lib/validate.js";

  import { Button, Checkbox, IconButton, Search, Tooltip } from "components";

  export let type;
  export let items;
  export let active;
  export let dirty;

  $: checked = $types[type].length > 0;
  $: tooltipMessage =
    $t("confirmation_new_type_item.tooltip") &&
    $t("confirmation_new_type_item.tooltip").replace(
      "%{type}",
      $t(`confirmation.types.${type}`).toLowerCase(),
    );

  function modifySearchResults(data) {
    return data.map((item) => {
      const user = {
        label: item.email,
        value: item.permalink,
      };

      return user;
    });
  }

  function check(value) {
    checked = value;

    if (value) {
      addItem();
    } else {
      let newTypes = { ...$types };

      newTypes[type] = [];

      types.set(newTypes);
    }
  }

  function allowedCheck(index, value) {
    let newTypes = { ...$types };
    newTypes[type][index].allowed = value;
    types.set(newTypes);
  }

  function manageConfirmationItemsCheck(index, value) {
    let newTypes = { ...$types };
    newTypes[type][index].can_manage_confirmation_items = value;
    types.set(newTypes);
  }

  function addItem() {
    dirty = false;
    let newTypes = { ...$types };

    newTypes[type].push({
      email: "",
      allowed: false,
      can_manage_confirmation_items: true,
    });

    types.set(newTypes);
  }

  function onRemoveItem(index) {
    dirty = false;
    let newTypes = { ...$types };
    newTypes[type].splice(index, 1);
    types.set(newTypes);
  }
</script>

<div data-component="ConfirmationNewTypeItem" class={styles.wrapper}>
  {#if active}
    <div class={styles.header}>
      <Checkbox
        toggle={check}
        toggled={checked}
        label={$t(`confirmation.types.${type}`)}
      />
      <Tooltip nowrap={false}>
        <div slot="content">
          <img src={iconPath("info")} alt="info" />
        </div>

        <div slot="tooltip" class={styles.tooltip}>{tooltipMessage}</div>
      </Tooltip>
    </div>

    {#if checked}
      <div class={styles.items}>
        <div class={styles.subtitle}>
          {$t("confirmation_new_type_item.subtitle")}
        </div>
        {#each $types[type] as { email, allowed, can_manage_confirmation_items }, index}
          <div class={styles.item}>
            <div class={styles.contact}>
              <div class={styles.input}>
                <Search
                  icon={false}
                  arrows={true}
                  bind:value={email}
                  errors={dirty ? validate(email, ["email"]) : []}
                  enterIcon={"plus"}
                  searchApi={ClientUsersApi}
                  searchApiParams={{ client_id: $client.id }}
                  placeholder={$t(
                    "confirmation_new_type_item.client_contact_placeholder",
                  )}
                  {modifySearchResults}
                >
                  <div slot="empty" class={styles.empty}>
                    <div>
                      {$t("confirmation_new_type_item.email_not_exist")}
                    </div>
                    <div>{$t("confirmation_new_type_item.enter_to_add")}</div>
                  </div>
                </Search>
              </div>

              {#if $types[type].length > 1}
                <IconButton
                  style="error-text"
                  icon="trash.svg"
                  click={() => onRemoveItem(index)}
                />
              {/if}
            </div>

            <div class={styles.allowed}>
              <Checkbox
                toggle={allowedCheck.bind(this, index)}
                toggled={allowed}
                label={$t("confirmation_new_type_item.contact_allowed")}
              />
            </div>

            <div class={styles.allowed}>
              <Checkbox
                toggled={can_manage_confirmation_items}
                toggle={manageConfirmationItemsCheck.bind(this, index)}
                label={$t(
                  "confirmation_new_type_item.manage_confirmation_items",
                )}
              />
            </div>
          </div>
        {/each}

        <div class={styles.add}>
          <Button style="primary-tonal" click={addItem}
            >{$t("confirmation_new_type_item.add_item")}</Button
          >
        </div>
      </div>
    {/if}
  {:else if checked}
    <div class={styles.subtitle}>{$t(`confirmation.types.${type}`)}</div>

    {#each items as { email }}
      <div>{email}</div>
    {/each}
  {/if}
</div>

<style lang="scss">
  .wrapper {
    margin-bottom: 8px;
    min-height: 40px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tooltip {
    width: 210px;
  }

  .items {
    margin-left: 25px;
  }

  .item {
    padding-bottom: 12px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
    margin-top: 10px;
  }

  .contact {
    display: flex;
    margin-top: 10px;
  }

  .input {
    flex: 1;
    margin-right: 10px;
  }

  .allowed {
    margin-top: 10px;
  }

  .add {
    margin-top: 10px;
  }

  .empty {
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    margin: -4px 0;
    padding: 35px 10px;

    div {
      &:first-child {
        color: var(--primary-300);
      }

      &:last-child {
        font-weight: 500;
        color: var(--green-400);
      }
    }
  }
</style>
