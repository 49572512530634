<script>
  import styles from "styleguide/UserPicture.json";

  import { Tooltip } from "components";

  export let name = "";
  export let avatar;
  export let highlighted;
  export let smallAvatar;
  export let isUserMenu = false;
  export let active = false;
  export let isAvatarClickable = true;

  $: initials = getInitials(name);

  function getInitials(name) {
    return (
      name &&
      name
        .split(" ")
        // Use first letter from the First and Last name, ignore Middle name(s)
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join("")
        .toLocaleUpperCase()
    );
  }

  $: calculatedStyles = function () {
    let s = ["wrapper"];
    if (active) {
      s.push("active");
    }
    if (highlighted) {
      s.push("highlighted");
    }
    if (avatar) {
      s.push("has-avatar");
    }
    if (smallAvatar) {
      s.push("small-avatar");
    }
    if (isAvatarClickable) {
      s.push("clickable");
    }
    return s.map((x) => styles[x]).join(" ");
  };

  function toogleActiveState() {
    if (isAvatarClickable) {
      active = !active;
    }
  }
</script>

<Tooltip on:click showArrow={true} forceHide={isUserMenu}>
  <div
    slot="content"
    data-component="UserPicture"
    class={calculatedStyles()}
    on:click={toogleActiveState}
  >
    {#if avatar}
      <img class={styles.avatar} src={avatar} alt={name} />
    {:else}
      <div class={styles.initials}>{initials}</div>
    {/if}
  </div>
  <svelte:fragment slot="tooltip">
    {#if name && name !== "+1"}
      <span class={styles.name}>{name}</span>
    {/if}
  </svelte:fragment>
</Tooltip>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 1px var(--primary-050);
    background-color: var(--white);
    overflow: hidden;

    &.has-avatar {
      background-color: var(--brand-010);
    }

    &.small-avatar {
      width: 32px;
      height: 32px;
    }

    &.highlighted {
      background-color: rgba(254, 235, 119, 0.15);
    }

    &.clickable:hover {
      border-color: var(--brand-100);
      img {
        opacity: 0.8;
      }
    }
    &.clickable:active {
      border-color: var(--brand-100);
      img {
        opacity: 0.6;
      }
    }

    &.clickable.active {
      outline: 3px solid var(--white);
      border-color: var(--brand-100);
    }
  }

  .avatar {
    width: 100%;
  }

  .name {
    white-space: nowrap;
  }

  .initials {
    font-weight: 500;
  }
</style>
