<script>
  import { onMount } from "svelte";

  import { user } from "stores/user.js";
  import { breadcrumb } from "stores/breadcrumb.js";
  import {
    receiveProject,
    projectActivities,
  } from "stores/dashboard/project.js";
  import {
    reset,
    availableConfirmationTypes,
  } from "stores/confirmation/confirmation_new.js";
  import { updatePageHistory } from "stores/page_history.js";

  import {
    DashboardProjectHeader,
    DashboardProjectShowContent,
    DashboardProjectSidebar,
  } from "apps/dashboard";

  import { ContentSidebar } from "components";

  export let initialState;

  onMount(async () => {
    if (initialState.project) {
      receiveProject(initialState.project);
    }

    if (initialState.activities) {
      projectActivities.set(initialState.activities);
    }

    if (initialState.available_confirmation_types) {
      availableConfirmationTypes.set(initialState.available_confirmation_types);
      reset();
    }

    breadcrumb.set([
      { translation: "projects", path: "." },
      { name: initialState.project.name },
    ]);

    updatePageHistory([
      {
        path: initialState.project.project_path,
        titles: [
          { translation: "projects" },
          { name: initialState.project.name },
        ],
      },
    ]);
  });
</script>

<div data-component="DashboardProjectShow">
  <DashboardProjectHeader navigationTo={"show"} />

  <ContentSidebar>
    <div slot="content">
      <DashboardProjectShowContent />
    </div>

    <div slot="sidebar">
      {#if $user.role !== "client"}
        <DashboardProjectSidebar />
      {/if}
    </div>
  </ContentSidebar>
</div>
