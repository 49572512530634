<script>
  import { t } from "stores/i18n.js";
  import { types } from "stores/confirmation/confirmation_new.js";

  import { validate } from "lib/validate.js";

  import {
    ConfirmationNewBlock,
    ConfirmationNewTypeItem,
  } from "apps/confirmationNew";

  export let active;
  export let dirty;
  export let valide;

  $: {
    valide = checkEmails($types);
  }

  function checkEmails(types) {
    const contacts = [].concat.apply([], Object.values(types));
    const emails = contacts.map((contact) => contact.email);
    const emailsWithErrors = emails.filter(
      (email) => validate(email, ["email"]).length,
    );
    return emailsWithErrors.length === 0;
  }
</script>

<ConfirmationNewBlock
  {active}
  edit={() => {
    active = true;
  }}
  title={$t("confirmation_new_type.title")}
>
  <div data-component="ConfirmationNewType">
    {#each Object.entries($types) as [type, items] (type)}
      <ConfirmationNewTypeItem {type} {items} {active} bind:dirty />
    {:else}
      <!-- CHECK not work array is true -->
      {#if !active}
        {$t("confirmation_new_type.empty")}
      {/if}
    {/each}
  </div>
</ConfirmationNewBlock>
