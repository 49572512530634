<script>
  import styles from "styleguide/ConfirmationNewClient.json";
  import { t } from "stores/i18n.js";
  import {
    client,
    title,
    senderAddress,
    billingData,
  } from "stores/confirmation/confirmation_new.js";
  import { theme } from "stores/theme.js";

  import { validate } from "lib/validate.js";
  import ClientsApi from "apis/users/clients.js";

  import { ConfirmationNewBlock } from "apps/confirmationNew";
  import { Dropdown, Search, TextInput } from "components";

  export let active;
  export let valide;
  export let dirty;
  export let clientLock = false;
  export let clientShow = true;

  $: titleErrors = validate($title, ["required"]);
  $: clientErrors = clientLock ? [] : validate($client.id, ["required"]);
  $: orderNumberErrors = validate(
    $billingData.order_number,
    ["required", "numeric", "length"],
    { min: 1, max: 6 },
  );
  $: partNumberErrors = validate($billingData.part_number, ["numeric"]);
  $: costErrors = validate(
    $billingData.cost,
    ["required", "numeric", "length"],
    { min: 8, max: 8 },
  );
  $: valide =
    !titleErrors.length &&
    !clientErrors.length &&
    ($theme == "ebde"
      ? !orderNumberErrors.length &&
        !partNumberErrors.length &&
        !costErrors.length
      : true);

  let creationYears = Array.from({ length: 6 }, (v, k) => k + 2019).reduce(
    (o, v) => {
      o[v] = v;
      return o;
    },
    {},
  );

  function modifySearchResults(users) {
    return users.map((item) => {
      const user = {
        label: item.text,
        value: item.id,
      };

      return user;
    });
  }

  function getSelected(id, data) {
    client.set(data.find((item) => item.id === id));
  }
</script>

<ConfirmationNewBlock
  {active}
  edit={() => {
    active = true;
  }}
>
  <div data-component="ConfirmationNewClient">
    {#if clientShow}
      <div class={styles.input}>
        {#if active}
          <Search
            icon={false}
            clear={false}
            bind:value={$client.text}
            searchApi={ClientsApi}
            getOrigin={getSelected}
            {modifySearchResults}
            lock={clientLock}
            errors={dirty ? clientErrors : []}
            style="border medium round-border fullsize"
            label={$t("confirmation.settings.client")}
            placeholder={$t("confirmation_new_client.client_placeholder")}
          />
        {:else}
          <div class={styles.subtitle}>
            {$t("confirmation.settings.client")}
          </div>
          <div>{$client.text}</div>
        {/if}
      </div>
    {/if}

    <div class={styles.input}>
      {#if active}
        <TextInput
          bind:value={$title}
          errors={dirty ? titleErrors : []}
          style="border medium round-border fullsize"
          label={$t("confirmation_new_client.title_label")}
          placeholder={$t("confirmation_new_client.title_label")}
          footnotes={[$t("confirmation_new_client.title_footnote")]}
        />
      {:else}
        <div class={styles.subtitle}>
          {$t("confirmation_new_client.title_label")}
        </div>
        <div>{$title}</div>
      {/if}
    </div>

    <div class={styles.input}>
      {#if active}
        <TextInput
          optional={true}
          bind:value={$senderAddress}
          style="border medium round-border fullsize"
          label={$t("confirmation_new_client.sender_address_label")}
          placeholder={$t("confirmation_new_client.sender_address_label")}
          footnotes={[$t("confirmation_new_client.sender_address_footnote")]}
        />
      {:else}
        <div class={styles.subtitle}>
          {$t("confirmation_new_client.sender_address_label")}
        </div>
        <div>{$senderAddress}</div>
      {/if}
    </div>

    {#if $theme === "ebde"}
      <div class={styles.input}>
        {#if active}
          <Dropdown
            items={creationYears}
            initial={2019}
            bind:selected={$billingData.order_year}
            label={$t("confirmation.settings.order_year")}
          />
        {:else}
          <div class={styles.subtitle}>
            {$t("confirmation.settings.order_year")}
          </div>
          <div>{$billingData.order_year}</div>
        {/if}
      </div>

      <div class={styles.input}>
        {#if active}
          <TextInput
            maxlength={6}
            bind:value={$billingData.order_number}
            label={$t("confirmation.settings.order_number")}
            footnotes={[
              $t("confirmation_new_client.ebde_order_number_footnote"),
            ]}
            errors={dirty ? orderNumberErrors : []}
          />
        {:else}
          <div class={styles.subtitle}>
            {$t("confirmation.settings.order_number")}
          </div>
          <div>{$billingData.order_number}</div>
        {/if}
      </div>

      <div class={styles.input}>
        {#if active}
          <TextInput
            maxlength={2}
            optional={true}
            bind:value={$billingData.part_number}
            label={$t("confirmation.settings.part_number")}
            footnotes={[
              $t("confirmation_new_client.ebde_part_number_footnote"),
            ]}
            errors={dirty ? partNumberErrors : []}
          />
        {:else}
          <div class={styles.subtitle}>
            {$t("confirmation.settings.part_number")}
          </div>
          <div>{$billingData.part_number}</div>
        {/if}
      </div>

      <div class={styles.input}>
        {#if active}
          <TextInput
            maxlength={8}
            bind:value={$billingData.cost}
            label={$t("confirmation.settings.cost")}
            footnotes={[$t("confirmation_new_client.ebde_cost_footnote")]}
            errors={dirty ? costErrors : []}
          />
        {:else}
          <div class={styles.subtitle}>{$t("confirmation.settings.cost")}</div>
          <div>{$billingData.cost}</div>
        {/if}
      </div>
    {/if}
  </div>
</ConfirmationNewBlock>

<style lang="scss">
  .input {
    margin-bottom: 16px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
  }
</style>
