import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class Comments {
  constructor() {
    this.endpoint = "/comments";
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(`${this.endpoint}`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  getComments(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}`, {
      params,
      success: (response) => {
        success(response.data.comments);
      },
      error,
    });
  }

  delete(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    formData.append("parent_id", params.parent_id);
    formData.append("parent_type", params.parent_type);

    Api.delete(`${this.endpoint}/${params.permalink}`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  markAsRead(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    formData.append("parent_id", params.parent_id);
    formData.append("parent_type", params.parent_type);

    Api.post(`${this.endpoint}/mark_as_read`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  downloadAttachment(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.permalink}/download_attachment`, {
      params,
      success,
      error,
    });
  }
}

export default new Comments();
