<script>
  import {
    confirmation,
    confirmationId,
    activeArchive,
  } from "stores/confirmation/confirmation.js";
  import { t } from "stores/i18n.js";

  import ConfirmationApi from "apis/confirmation/confirmation.js";

  import { Button } from "components";
  import { Modal } from "components/modals";

  import styles from "styleguide/ConfirmationArchiveModal.json";

  function onHide() {
    activeArchive.set(false);
  }

  function archiveProject(archived) {
    const isConfirmed = confirm($t("questions.sure"));

    if (isConfirmed) {
      ConfirmationApi.archive({
        params: {
          archived,
          id: $confirmationId,
        },
        success: () => {
          window.location = $confirmation.project_path;
        },
      });
    }
  }
</script>

{#if $activeArchive}
  <Modal
    shown={$activeArchive}
    {onHide}
    title={$t("confirmation_archive_modal.archive_title")}
    style={"error"}
  >
    <div data-component="ConfirmationArchiveModal">
      <p>
        {@html $t("confirmation_archive_modal.archive_description")}
      </p>
      <div class={styles.confirmation}>
        <Button style={"error"} click={archiveProject.bind(this, true)}>
          {$t("confirmation_archive_modal.archive_button")}
        </Button>
      </div>
    </div>
  </Modal>
{/if}

<style lang="scss">
  .description {
    color: var(--primary-500);
    margin-bottom: 15px;
  }

  .confirmation {
    display: flex;
  }

  .input {
    width: 150px;
    margin-right: 12px;
  }

  p {
    margin-top: 0;
  }
</style>
