<script>
  import { DashboardProjectItem, DashboardStatus } from "apps/dashboard";
  import {
    DashboardAuditNewModal,
    ProductSelectionModal,
    SandboxLoadingModal,
  } from "components/modals";
  import { onMount } from "svelte";
  import {
    newAuditProjectId,
    newAuditProjectName,
  } from "stores/dashboard/audit_new.js";

  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { isAccountFeature } from "stores/account";

  import {
    statusesConfig,
    collapsedGroups,
    setCollapsedGroups,
    statusesCount,
  } from "stores/dashboard/project.js";

  import { iconPath } from "lib/helpers.js";
  import { ConfirmationNew } from "apps";

  import {
    Boundary,
    Box,
    Button,
    Skeleton,
    Tooltip,
    UserPictures,
  } from "components";
  import styles from "styleguide/DashboardProjectGroup.json";

  export let name;
  export let items;
  export let status;
  export let users;
  export let access;
  export let permalink;
  export let project_path;
  export let loading;

  let itemsShow = false;
  let showConfirmationModal = false;
  let showNewAuditModal = false;
  let showSandboxModal = false;
  let addConformation = false;

  $: itemsShow = !($collapsedGroups[permalink] === 1);
  $: tags = combineItemTags($statusesCount);
  $: showProductOverviewModal =
    addConformation &&
    !showConfirmationModal &&
    !showSandboxModal &&
    !showNewAuditModal;
  $: showAddItemsButton =
    createPermission("sba", "review") ||
    createPermission("xba", "confirmation") ||
    createPermission("pbc", "audit");
  $: itemsWithStatuses = items;

  onMount(() => {
    loading = true;
  });

  function goToProject(event) {
    event.preventDefault();
    window.location = project_path;
  }

  function onToggleItems() {
    itemsShow = !itemsShow;

    const newCollapsedGroups = { ...$collapsedGroups };
    newCollapsedGroups[permalink] = itemsShow ? 0 : 1;
    setCollapsedGroups(newCollapsedGroups);
  }

  const getStatusesTags = (item, filteredItems) => {
    const combinedItems = { ...item, ...filteredItems };

    return Object.keys(combinedItems).reduce((statusesTags, property) => {
      if (
        statusesConfig.includes(property) &&
        (item[property] || filteredItems[property])
      ) {
        const count = (item[property] || 0) + (filteredItems[property] || 0);
        const existingTag = statusesTags.find((tag) => tag.status === property);

        if (existingTag) {
          existingTag.count += count;
        } else {
          statusesTags.push({ status: property, count });
        }
      }

      return statusesTags;
    }, []);
  };

  function combineItemTags(statusesCount) {
    let statusesByGroups = statusesCount?.find(
      (item) => item.permalink === permalink,
    );

    itemsWithStatuses = items.map((item) => {
      const filteredItems = statusesByGroups?.[item.type]?.find(
        (group) => group.permalink === item.permalink,
      );

      return {
        ...item,
        ...filteredItems,
        statuses: getStatusesTags(item, filteredItems),
      };
    });

    const tags = itemsWithStatuses.reduce((acc, item) => {
      for (const property in item) {
        if (statusesConfig.includes(property) && item[property]) {
          acc[property] = (acc[property] || 0) + item[property];
        }
      }
      return acc;
    }, {});

    return tags;
  }

  function onCloseNewAuditModal() {
    showNewAuditModal = false;
    addConformation = true;
  }

  function onSandboxModalHide() {
    addConformation = true;
    showSandboxModal = false;
  }

  function createPermission(feature, action) {
    return $isAccountFeature(feature) && $can("create", action);
  }
</script>

<Box>
  <Boundary>
    <div data-component="DashboardProjectGroup">
      <div class={`${styles.header} ${itemsShow ? "" : styles.collapsed}`}>
        <Skeleton {loading}>
          <div class={styles["header-item"]}>
            <div class={styles.status}>
              <DashboardStatus {status} type="group" />
            </div>
            <a href={project_path} class={styles.name} on:click={goToProject}
              >{name}</a
            >
          </div>
          <div class={styles["header-item"]}>
            {#if itemsShow}
              {#if access === "private"}
                <div class={styles.lock}>
                  <Tooltip showArrow>
                    <div slot="content" class={styles.wrapper}>
                      <img src={iconPath("lock")} alt="" />
                    </div>
                    <div slot="tooltip">
                      <span class={styles.private}>
                        {$t("dashboard_group_items.private")}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              {:else if access === "confidential"}
                <div class={styles.shield}>
                  <Tooltip showArrow>
                    <div slot="content" class={styles.wrapper}>
                      <img src="/assets/svg_icons/shield.svg" alt="" />
                    </div>
                    <div slot="tooltip">
                      <span class={styles.confidential}>
                        {$t("dashboard_group_items.confidential")}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              {/if}

              <div class={styles.users}>
                <UserPictures {users} isAvatarClickable={false} />
              </div>
            {/if}
            <div class={styles.options}>
              {#if itemsShow}
                {#if showAddItemsButton}
                  <Button
                    click={() => {
                      addConformation = true;
                    }}
                    style={"primary-tonal small"}
                  >
                    {$t("dashboard_group_items.add_item")}
                  </Button>

                  <SandboxLoadingModal
                    bind:showModal={showSandboxModal}
                    onHide={onSandboxModalHide}
                  />

                  <ProductSelectionModal
                    bind:showModal={showProductOverviewModal}
                    bind:showConfirmationModal
                    bind:showSandboxModal
                    bind:showNewAuditModal
                    projectName={name}
                    projectPermalink={permalink}
                    onHide={() => {
                      addConformation = false;
                    }}
                  />

                  <ConfirmationNew
                    projectId={permalink}
                    bind:shown={showConfirmationModal}
                  />
                {/if}
              {:else if tags}
                <div class={styles.tags}>
                  {#each Object.entries(tags) as [status, count] (status)}
                    <Tooltip>
                      <div slot="content" class={styles.tag}>
                        <DashboardStatus {status} {count} type="count" />
                      </div>

                      <div slot="tooltip" class={styles.tooltip}>
                        {$t("dashboard_status." + status, {
                          postProcess: "interval",
                          count,
                        })}
                      </div>
                    </Tooltip>
                  {/each}
                </div>
              {/if}
            </div>
            <Button click={onToggleItems} style={"primary-text small"}>
              {itemsShow
                ? $t("dashboard_group_items.collapse_project")
                : $t("dashboard_group_items.expand_project")}
            </Button>
          </div>
        </Skeleton>
      </div>
      {#if itemsShow}
        {#each itemsWithStatuses as item (item?.permalink)}
          {#if !item?.archived}
            <div class={styles.container}>
              <Skeleton {loading}>
                <DashboardProjectItem {...item} />
              </Skeleton>
            </div>
          {/if}
        {/each}
      {/if}
    </div>
    <DashboardAuditNewModal
      bind:shown={showNewAuditModal}
      projectId={$newAuditProjectId}
      projectName={$newAuditProjectName}
      onCancel={() => {
        onCloseNewAuditModal();
        newAuditProjectId.set(null);
      }}
    />
  </Boundary>
</Box>

<style lang="scss">
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    min-height: 64px;
    position: relative;
  }

  .header-item {
    display: flex;
    align-items: center;
  }

  .container {
    padding: 10px 20px 10px 35px;
    border-top: solid 1px var(--primary-050);
    min-height: 71px;
    position: relative;
  }

  .name {
    margin-left: 15px;
    font-size: 16px;
    line-height: 1.63;
  }

  .status {
    min-width: 82px;
  }

  .options {
    margin-right: 8px;
  }

  .lock,
  .shield {
    padding: 8px 16px 0 0;
  }

  .users {
    margin-right: 16px;
  }

  .collapsed {
    position: relative;
  }

  .collapsed:after,
  .collapsed:before {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
    position: absolute;
    height: 10px;
    content: "";
    border: 1px solid var(--primary-050);
    border-top: none;
    left: -1px;
  }

  .collapsed:before {
    bottom: -4px;
    opacity: 0.8;
  }

  .collapsed:after {
    bottom: -7px;
    opacity: 0.6;
  }

  .tags {
    display: flex;
  }

  .tag {
    margin: 0 4px;
  }

  .tooltip:first-letter {
    text-transform: uppercase;
  }
</style>
