/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// POLYFILLS
import "regenerator-runtime/runtime";
import "./lib/polyfills";
import "abortcontroller-polyfill";
import "core-js/stable";
import "md-gum-polyfill";
import "resize-observer-polyfill";
import "whatwg-fetch"; // .fetch by github

// PREFLIGHT CHECK
$(function () {
  console.log("Webpacker initialized");
});

// CORE defined in envrionment.js

// INIT
Rails.start();
Turbolinks.start();

// LIBS
import jquery from "jquery";
window.$ = jquery;
window.jQuery = jquery;
import Cleave from "cleave.js";

// SVELTE APPS
import AdministrationTeams from "./apps/administration/components/AdministrationTeams.svelte";
import {
  Administration,
  Confirmation,
  ConfirmationItem,
  ConfirmationSigner,
  Dashboard,
  Frontend,
  InvoiceAccess,
  LegacyApp,
  PBC,
  ProfileSettings,
  Respond,
  Responder,
  RestrictedAccess,
  Styleguide,
} from "apps";
import {
  DocumentThumbnails,
  Toasts,
  Toast,
  TextEditor,
  SidebarNotifications,
} from "components";

WebpackerSvelte.setup({
  PBC,
  Respond,
  Responder,
  Dashboard,
  Frontend,
  DocumentThumbnails,
  ProfileSettings,
  Confirmation,
  ConfirmationItem,
  ConfirmationSigner,
  InvoiceAccess,
  TextEditor,
  SidebarNotifications,
  LegacyApp,
  Toasts,
  Toast,
  Administration,
  AdministrationTeams,
  Styleguide,
  RestrictedAccess,
  Icons,
  CountriesIcons,
  ReviewSidebarSection,
});

import "./lib/cocoon"; // copied from gem repo

// STIMULUS
import "./controllers/index";

// COMPONENTS
import "./src/scroll";
import "./src/samples";
import "./src/navigation";
import "./src/reviews";
import "./src/forms";
import "./src/samplings";
import "./src/permissions_list";
import "./src/menu";
import "./src/tooltip";
import CountriesIcons from "components/icons/countries/CountriesIcons.svelte";
import Icons from "components/icons/Icons.svelte";
import ReviewSidebarSection from "components/review/ReviewSidebarSection.svelte";
import Rails from "rails-ujs";
import { Trix } from "trix";
import Turbolinks from "turbolinks";
import WebpackerSvelte from "webpacker-svelte";
