<script>
  import styles from "styleguide/ConfirmationNewDate.json";
  import { theme } from "stores/theme.js";
  import { t } from "stores/i18n.js";
  import {
    deadline,
    startSending,
    responsePeriod,
    targetDate,
    billingData,
    fixedResponsePeriod,
    fiscalYearBeginningDate,
  } from "stores/confirmation/confirmation_new.js";

  import { dateTimeFormat } from "lib/helpers.js";
  import ClientsApi from "apis/users/team_users.js";

  import { ConfirmationNewBlock } from "apps/confirmationNew";
  import { DateInput, Dropdown, Search, TextInput, Toast } from "components";

  export let active;
  export let valide;
  export let dirty;
  export let showDeadline = true;
  export let hasImplausibleDates;
  export let resetDatesValidation;

  let creationYears = Array.from({ length: 6 }, (v, k) => k + 2019).reduce(
    (o, v) => {
      o[v] = v;
      return o;
    },
    {},
  );
  let deadlines = ["3", "7", "14", "21", "30"];
  $: deadlinesDays = combineDeadlinesDays($t);
  $: valide =
    $deadline &&
    $targetDate &&
    $fiscalYearBeginningDate &&
    ($theme == "made"
      ? $billingData.auftragsnummer &&
        $billingData.auftragspartner.id &&
        $billingData.auftragspartner.text
      : true);

  function combineDeadlinesDays() {
    let obj = {};

    if (!$t("datetime.days")) return obj;

    deadlines.forEach((item) => {
      obj[item] = $t("datetime.days").replace("%{count}", item);
    });

    return obj;
  }

  function modifySearchResults(response) {
    let users = response.data.team_users;

    return users.map((item) => {
      const user = {
        label: item.text,
        value: item.id,
      };

      return user;
    });
  }

  function getSelected(id, response) {
    let users = response.data.team_users;

    billingData.update((billing) => {
      let newBilling = { ...billing };
      newBilling.auftragspartner = users.find((item) => item.id === id);
      return newBilling;
    });
  }
</script>

<ConfirmationNewBlock
  {active}
  edit={() => {
    active = true;
  }}
  title={$t("confirmation_new_date.title")}
>
  <div data-component="ConfirmationNewDate">
    {#if active}
      {#if $theme === "made"}
        <div class={styles.input}>
          <Dropdown
            items={creationYears}
            bind:selected={$billingData.anlagejahr}
            label={$t("confirmation.settings.creation_year")}
            footnotes={[$t("confirmation_new_date.made_anlagejahr")]}
          />
        </div>

        <div class={styles.input}>
          <TextInput
            bind:value={$billingData.auftragsnummer}
            label={$t("confirmation.settings.order_number")}
            footnotes={[
              $t("confirmation_new_date.made_auftragsnummer_footnote"),
            ]}
            errors={dirty && !$billingData.auftragsnummer
              ? [$t("validation.required")]
              : []}
          />
        </div>

        <div class={styles.input}>
          <Search
            icon={false}
            clear={false}
            bind:value={$billingData.auftragspartner.text}
            searchApi={ClientsApi}
            getOrigin={getSelected}
            {modifySearchResults}
            style="border medium round-border fullsize"
            label={$t("confirmation.settings.order_partner")}
            footnotes={[$t("confirmation_new_date.order_partner_placeholder")]}
            errors={dirty &&
            (!$billingData.auftragspartner.id ||
              !$billingData.auftragspartner.text)
              ? [$t("validation.required")]
              : []}
          />
        </div>
      {/if}

      <div class={styles.datepicker}>
        <DateInput
          onSelect={resetDatesValidation}
          bind:value={$fiscalYearBeginningDate}
          label={$t("confirmation.settings.fiscal_year_beginning_date")}
          errors={dirty && !$fiscalYearBeginningDate
            ? [$t("confirmation_new_date.fiscal_year_beginning_error")]
            : []}
        />
      </div>

      <div class={styles.datepicker}>
        <DateInput
          onSelect={resetDatesValidation}
          bind:value={$targetDate}
          label={$t("confirmation.settings.target_date")}
          errors={dirty && !$targetDate
            ? [$t("confirmation_new_date.target_date_error")]
            : []}
        />
      </div>

      {#if showDeadline}
        <div class={styles.datepicker}>
          <DateInput
            onSelect={resetDatesValidation}
            bind:value={$deadline}
            label={$t("confirmation.settings.deadline")}
            errors={dirty && !$deadline
              ? [$t("confirmation_new_date.deadline_error")]
              : []}
          />
        </div>
      {/if}

      <div class={styles.input}>
        <Dropdown
          items={deadlinesDays}
          bind:selected={$responsePeriod}
          label={$t("confirmation.settings.response_period")}
          footnotes={[$t("confirmation_new_date.deadline_footnote")]}
        />
      </div>

      <div class={styles.datepicker}>
        <DateInput
          onSelect={resetDatesValidation}
          optional={true}
          bind:value={$fixedResponsePeriod}
          label={$t("confirmation.settings.fixed_response_period")}
          footnotes={[
            $t("confirmation_new_date.deadline_alternative_footnote"),
          ]}
        />
      </div>

      <div class={styles.datepicker}>
        <DateInput
          onSelect={resetDatesValidation}
          optional={true}
          bind:value={$startSending}
          label={$t("confirmation.settings.start_sending_at")}
          footnotes={[$t("confirmation_new_date.deadline_earliest_footnote")]}
        />
      </div>

      {#if hasImplausibleDates}
        <Toast
          type="error"
          title={$t("confirmation_new_date.toast_error_title")}
        />
      {/if}
    {:else}
      {#if $theme == "made"}
        <div class={styles.value}>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.creation_year")}
          </div>
          <div>{$billingData.anlagejahr}</div>
        </div>
        <div class={styles.value}>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.order_number")}
          </div>
          <div>{$billingData.auftragsnummer}</div>
        </div>
        <div class={styles.value}>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.order_partner")}
          </div>
          <div>{$billingData.auftragspartner.text}</div>
        </div>
      {/if}
      {#if $targetDate}
        <div class={styles.value}>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.target_date")}
          </div>
          <div>{dateTimeFormat($targetDate).split(",")[0]}</div>
        </div>
      {/if}
      {#if $deadline}
        <div class={styles.value}>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.deadline")}
          </div>
          <div>{dateTimeFormat($deadline).split(",")[0]}</div>
        </div>
      {/if}
      {#if $fiscalYearBeginningDate}
        <div class={styles.value}>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.fiscal_year_beginning_date")}
          </div>
          <div>{dateTimeFormat($fiscalYearBeginningDate).split(",")[0]}</div>
        </div>
      {/if}
      <div class={styles.value}>
        <div class={styles.subtitle}>
          {$t("confirmation.settings.response_period")}
        </div>
        <div>{$t("datetime.days").replace("%{count}", $responsePeriod)}</div>
      </div>
      {#if $fixedResponsePeriod}
        <div class={styles.value}>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.fixed_response_period")}
          </div>
          <div>{dateTimeFormat($fixedResponsePeriod).split(",")[0]}</div>
        </div>
      {/if}
      {#if $startSending}
        <div class={styles.value}>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.start_sending_at")}
          </div>
          <div>{dateTimeFormat($startSending).split(",")[0]}</div>
        </div>
      {/if}
    {/if}
  </div>
</ConfirmationNewBlock>

<style lang="scss">
  .input,
  .datepicker {
    margin-bottom: 16px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
  }

  .value {
    margin-bottom: 10px;
  }
</style>
