<script>
  import styles from "styleguide/ConfirmationNew.json";
  import { onMount } from "svelte";

  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import {
    create,
    reset,
    deadline,
    startSending,
    fixedResponsePeriod,
    fiscalYearBeginningDate,
    targetDate,
  } from "stores/confirmation/confirmation_new.js";

  import { isDateAfterDate } from "lib/helpers.js";

  import {
    ConfirmationNewClient,
    ConfirmationNewDate,
    ConfirmationNewInvitation,
    ConfirmationNewType,
    ConfirmationNewLetterhead,
  } from "apps/confirmationNew";

  import { Button } from "components";
  import { Modal } from "components/modals";

  export let shown = false;
  export let projectId;
  export let hasImplausibleDates = false;

  let step = 1;
  let touchedSteps = {
    "1": {
      dirty: false,
      valide: false,
    },
    "2": {
      dirty: false,
      valide: false,
    },
    "3": {
      dirty: false,
      valide: false,
    },
    "4": {
      dirty: false,
      valide: true,
    },
  };

  function resetDatesValidation() {
    hasImplausibleDates = false;
  }

  function validatePlausibleDates() {
    hasImplausibleDates =
      isDateAfterDate($fiscalYearBeginningDate, $targetDate) ||
      isDateAfterDate($fiscalYearBeginningDate, $deadline) ||
      isDateAfterDate($startSending, $fixedResponsePeriod);
  }

  function changeStep(offset) {
    validatePlausibleDates();
    if (hasImplausibleDates) return;

    if (offset > 0) {
      touchedSteps[step].dirty = true;
    } else {
      touchedSteps[step + offset].dirty = false;
    }

    if (offset > 0 && !touchedSteps[step].valide) {
      return;
    }

    if (offset > 0 && step === 4) {
      create({
        projectId,
        success: (data) => {
          window.location = data.url;
        },
        error: (response) => {
          if (response.code && response.code.name) {
            toasts.send({
              title: $t("errors.name_is_already_taken"),
              type: "error",
            });
          } else {
            toasts.send({ title: $t("errors.check_entries"), type: "error" });
          }
        },
      });
    } else {
      step += offset;
    }
  }

  onMount(() => {
    reset();
  });
</script>

<Modal
  style="bottom-between"
  {shown}
  title={$t("confirmation_new_modal.title")}
  onHide={() => {
    shown = false;
  }}
>
  <div class={styles.wrapper} data-component="ConfirmationNew">
    <ConfirmationNewClient
      bind:valide={touchedSteps[1].valide}
      dirty={touchedSteps[1].dirty}
      active={step === 1}
    />

    {#if step > 1}
      <ConfirmationNewType
        bind:valide={touchedSteps[2].valide}
        bind:dirty={touchedSteps[2].dirty}
        active={step === 2}
      />
    {/if}

    {#if step > 2}
      <ConfirmationNewDate
        {resetDatesValidation}
        {hasImplausibleDates}
        bind:valide={touchedSteps[3].valide}
        bind:dirty={touchedSteps[3].dirty}
        active={step === 3}
      />
    {/if}

    {#if step > 3}
      <ConfirmationNewLetterhead active={step === 4} />
      <ConfirmationNewInvitation active={step === 4} />
    {/if}
  </div>

  <svelte:fragment slot="bottom">
    <div>
      {#if step > 1}
        <Button style="primary-text" click={changeStep.bind(this, -1)}>
          {$t("confirmation_new_modal.back")}
        </Button>
      {/if}
    </div>

    <div>
      {#if step === 4}
        <Button
          disabled={hasImplausibleDates}
          style="primary"
          click={changeStep.bind(this, 1)}
        >
          {$t("confirmation_new_modal.create")}
        </Button>
      {:else}
        <Button
          disabled={hasImplausibleDates}
          style="primary-tonal"
          click={changeStep.bind(this, 1)}
        >
          {$t("confirmation_new_modal.next")}
        </Button>
      {/if}
    </div>
  </svelte:fragment>
</Modal>

<style lang="scss">
  .wrapper {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
</style>
