<script>
  import { ConfirmationCreateItemSigner } from "apps/confirmation";
  import { ConfirmationNewCompanies } from "apps/confirmationNew";
  import { Modal } from "components/modals";
  import {
    confirmation,
    maxSignersPerItem,
  } from "stores/confirmation/confirmation.js";
  import { companies } from "stores/confirmation/confirmation_new.js";
  import { t } from "stores/i18n.js";
  import { theme } from "stores/theme.js";
  import { toasts } from "stores/toasts.js";
  import { flip } from "svelte/animate";
  import { dndzone } from "svelte-dnd-action";
  import ConfirmationItemsApi from "apis/confirmation/confirmation_items.js";
  import ConfirmationSignersApi from "apis/confirmation/confirmation_signers.js";
  import { validate } from "lib/validate.js";
  import { Button, Dropdown, CountryInput, TextInput } from "components";
  import styles from "styleguide/ConfirmationItemModal.json";

  export let action;
  export let shown;
  export let hide;
  export let success;
  export let data;
  export let type;
  export let confirmationId;
  export let signersChanged = false;

  let dirty = false;
  let AddsignerDirty = false;
  let SignerCantBeReminded = false;
  let isSort = false;
  let signersValid = [];
  let _item = { confirmation_signers: [] };

  const flipDurationMs = 200;

  $: cantDeleteSigner = false; // always allow deleting signers
  $: addressEditable = shown ? !_item.address_validated : true;
  $: zipErrors = shown
    ? validate(_item.zip, ["required", "zip"], { country: _item.country })
    : [];

  $: types = $confirmation.available_confirmation_types
    ? $confirmation.available_confirmation_types.reduce(
        (prev, curr) => ({ ...prev, [curr]: $t(`confirmation.types.${curr}`) }),
        {},
      )
    : {};

  $: languages = {
    "": $t("confirmation.language.automatically_recognise_language"),
    en: $t("confirmation.language.cover_letter_in_en"),
    de: $t("confirmation.language.cover_letter_in_de"),
  };

  $: init(shown, data);

  function init() {
    if (action === "edit" && data) {
      setData(data);
      combineSigners();
    } else {
      companies.set($confirmation.companies ? $confirmation.companies : []);
      if (type) _item.confirmation_type = type;
    }
  }

  function checkValid(_item) {
    if (!_item.company) return false;
    if (!_item.street) return false;
    if (!_item.city) return false;
    if (zipErrors.length) return false;
    if (!_item.country) return false;
    if (!_item.confirmation_type) return false;
    if (
      validate(_item.email, ["email"]) &&
      validate(_item.email, ["email"]).length
    )
      return false;
    if (!signersValid.every((item) => item)) return false;
    return true;
  }

  function setData(data) {
    _item.company = data.company;
    _item.contact = data.contact;
    _item.street = data.street;
    _item.city = data.city;
    _item.zip = data.zip;
    _item.country = data.country;
    _item.email = data.email;
    _item.confirmation_type = data.confirmation_type;
    _item.confirmation_signers = data.authorized_signers
      ? data.authorized_signers
      : [];
    _item.status = data.status;
    _item.language = data.language ? data.language : "";
    _item.address_validated = data.address_validated;
    companies.set(data.companies ? data.companies : []);
  }

  function addSigner() {
    AddsignerDirty = false;
    if (_item.confirmation_signers.length < maxSignersPerItem) {
      _item.confirmation_signers = [
        ..._item.confirmation_signers,
        { email: "", id: Date.now() },
      ];
    } else {
      AddsignerDirty = true;
    }
  }

  function combineSigners() {
    _item.confirmation_signers = _item.confirmation_signers.map((item) => {
      return {
        ...item,
        id: item.email,
      };
    });
  }

  function onSortConsider(e) {
    _item.confirmation_signers = e.detail.items;
  }

  function onSortFinalize(e) {
    isSort = false;

    _item.confirmation_signers = e.detail.items.map((item, index) => {
      return {
        ...item,
        position: index + 1,
      };
    });
  }

  function onDeleteSigner(id, index) {
    if (action === "edit" && id) {
      ConfirmationSignersApi.delete({
        params: {
          id,
          confirmation_id: data.confirmation_permalink,
          confirmation_item_id: data.permalink,
        },
        success: (signerId) => {
          signersChanged = true;
          _item.confirmation_signers = _item.confirmation_signers.filter(
            (item) => item.permalink !== signerId,
          );
        },
      });
    } else {
      let newSigners = _item.confirmation_signers;
      newSigners.splice(index, 1);
      _item.confirmation_signers = newSigners;
    }
    AddsignerDirty = false;
  }

  function onRemoveSignature(id) {
    if (confirm($t("questions.sure"))) {
      ConfirmationSignersApi.removeSignature({
        params: {
          id,
          confirmation_id: data.confirmation_permalink,
          confirmation_item_id: data.permalink,
        },
        success: () => {
          if (success) {
            success();
          }
          onHide();
        },
        error: () => {
          toasts.send({ title: $t("errors.check_entries"), type: "error" });
          SignerCantBeReminded = true;
        },
      });
    }
  }

  function getFilteredCompanies() {
    return $companies?.length
      ? $companies
          .filter((item) => item.name?.trim() !== "")
          .map((company) => ({
            name: company.name || company,
            accepted: company.accepted || "false",
          }))
      : null;
  }

  function getFilteredSigners() {
    _item.confirmation_signers = _item.confirmation_signers?.length
      ? _item.confirmation_signers
          .filter((item) => item.email?.trim() !== "")
          .map((signer) => ({ email: signer.email, id: signer.id }))
      : null;
  }

  function submitAdd() {
    getFilteredSigners();
    dirty = true;
    if (!checkValid(_item)) return;

    ConfirmationItemsApi.create({
      params: {
        ..._item,
        companies: getFilteredCompanies(),
        confirmation_id: $confirmationId,
      },
      success: () => {
        if (success) success();
        onHide();
        _item = { confirmation_signers: [] };
      },
      error: () => {
        toasts.send({ title: $t("errors.check_entries"), type: "error" });
      },
    });
  }

  function submitEdit() {
    dirty = true;
    // TODO: Remove temporary zip-revalidation
    zipErrors = validate(_item.zip, ["required", "zip"], {
      country: _item.country,
    });
    if (!checkValid(_item)) return;

    let params = { ..._item };
    params.id = data.permalink;
    params.companies = getFilteredCompanies();

    ConfirmationItemsApi.edit({
      params,
      success: () => {
        if (success) {
          signersChanged = false;
          success();
        }
        onHide();
      },
      error: () => {
        toasts.send({ title: $t("errors.check_entries"), type: "error" });
      },
    });
  }

  function onHide() {
    dirty = false;
    if (signersChanged) success();
    if (hide) hide();
  }
</script>

<Modal
  isWide
  bind:shown
  {onHide}
  title={$t(`confirmation_item_modal.title_${action}`)}
>
  <div class={styles.wrapper} data-component="ConfirmationItemModal">
    <div class={styles.inner}>
      <div class={styles.half}>
        <div class={styles.container}>
          <div class={styles.row}>
            <Dropdown
              lock={action === "edit" || type}
              initial={$t("confirmation_item_modal.select_type")}
              label={$t("confirmation_item_modal.type")}
              items={types}
              errors={dirty && !_item.confirmation_type
                ? [$t("validation.required")]
                : []}
              bind:selected={_item.confirmation_type}
            />
          </div>
        </div>

        <div class={styles.container}>
          <div class={styles.row}>
            <TextInput
              errors={dirty && !_item.company
                ? [$t("validation.required")]
                : []}
              disabled={!addressEditable}
              label={_item.confirmation_type
                ? $t(
                    `confirmation_item_modal.name_of_${_item.confirmation_type}`,
                  )
                : $t("confirmation_item_modal.name")}
              bind:value={_item.company}
            />
          </div>

          <div class={styles.row}>
            <TextInput
              disabled={!addressEditable}
              optional={true}
              label={$t("confirmation.form.contact")}
              bind:value={_item.contact}
            />
          </div>

          <div class={styles.row}>
            <TextInput
              disabled={!addressEditable}
              errors={dirty && !_item.street ? [$t("validation.required")] : []}
              label={$t("confirmation.form.street")}
              bind:value={_item.street}
            />
          </div>

          <div class={styles.row}>
            <div>
              <TextInput
                disabled={!addressEditable}
                errors={dirty ? zipErrors : []}
                label={$t("confirmation.form.zip")}
                bind:value={_item.zip}
              />
            </div>

            <div class={styles.row60}>
              <TextInput
                disabled={!addressEditable}
                errors={dirty && !_item.city ? [$t("validation.required")] : []}
                label={$t("confirmation.form.city")}
                bind:value={_item.city}
              />
            </div>
          </div>

          <div class={styles.row}>
            <CountryInput
              disabled={!addressEditable}
              errors={dirty && !_item.country
                ? [$t("validation.required")]
                : []}
              label={$t("confirmation.form.country")}
              bind:selected={_item.country}
            />
          </div>

          <div class={styles.row}>
            <TextInput
              disabled={!addressEditable}
              optional={true}
              errors={dirty ? validate(_item.email, ["email"]) : []}
              label={$t("confirmation.form.email")}
              bind:value={_item.email}
            />
          </div>

          {#if action === "edit"}
            <div class={styles.row}>
              <Dropdown items={languages} bind:selected={_item.language} />
            </div>
          {/if}
        </div>
      </div>

      <div class={styles.half}>
        <div class={styles.signer}>
          <div class={styles.container}>
            <div class={styles.label}>
              {$t("confirmation_item_modal.authorised_signers")}
            </div>

            {#if action === "add"}
              <div class={styles.footnote}>
                {$t("confirmation_item_modal.add_signer_provide")}
              </div>
            {/if}
          </div>

          {#if _item.confirmation_signers}
            <div class={styles.container}>
              <div
                use:dndzone={{
                  items: _item.confirmation_signers,
                  dropTargetStyle: {
                    outline: "rgba(255, 255, 255, 0.7) solid 2px",
                  },
                  flipDurationMs,
                  type: "signer",
                  dragDisabled: !isSort,
                }}
                on:consider={onSortConsider}
                on:finalize={onSortFinalize}
              >
                {#each _item.confirmation_signers as signer, index (signer.id)}
                  <div
                    animate:flip={{ duration: flipDurationMs }}
                    class="{styles.email} {$theme}-theme"
                  >
                    <ConfirmationCreateItemSigner
                      {index}
                      {dirty}
                      bind:value={signer.email}
                      bind:valid={signersValid[index]}
                      onSort={(value) => (isSort = value)}
                      onDelete={() => onDeleteSigner(signer.permalink, index)}
                      onRemoveSignature={() =>
                        onRemoveSignature(signer.permalink)}
                      disabled={action === "edit" && signer.permalink}
                      deleteButtonDisabled={cantDeleteSigner}
                      isEdit={action === "edit" ? true : false}
                    />
                  </div>
                {/each}
              </div>
            </div>
          {/if}

          <div class={styles.add}>
            <div class={styles.container}>
              {#if AddsignerDirty}
                <div class={styles["alert"]}>
                  {$t("confirmation_item_modal.max_signer_alert")}
                </div>
              {/if}
              {#if SignerCantBeReminded}
                <div class={styles["alert"]}>
                  {$t("confirmation_item_modal.signer_cant_be_reminded")}
                </div>
              {/if}
              <Button style={"primary-tonal"} click={addSigner}>
                {$t("confirmation_item_modal.add_signer")}
              </Button>
            </div>
          </div>
        </div>

        <ConfirmationNewCompanies />
      </div>
    </div>
  </div>

  <svelte:fragment slot="bottom">
    {#if action === "add"}
      <Button style={"primary"} click={submitAdd}>
        {$t("confirmation_item_modal.title_add")}
      </Button>
    {:else}
      <Button style={"primary"} click={submitEdit}>
        {$t("confirmation_item_modal.submit_edit")}
      </Button>
    {/if}
  </svelte:fragment>
</Modal>

<style lang="scss">
  .title {
    font-weight: 600;
    padding: 0 25px 10px;
    border-bottom: 1px solid var(--primary-050);
  }

  .inner {
    display: flex;
  }

  .half {
    width: 50%;
    padding-bottom: 25px;
    padding-right: 24px;
    &:nth-child(2) {
      padding-right: 0;
      padding-left: 24px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .row60 {
    width: 120%;
    margin-left: 10px;
  }

  .add {
    margin-top: 12px;
    padding-bottom: 25px;
  }

  .signer {
    margin-top: 20px;
  }

  .email {
    margin-top: 12px;
  }

  .label {
    font-weight: 500;
  }

  .footnote {
    margin-top: 12px;
    font-size: 12px;
    line-height: 1.5;
    color: var(--primary-300);
  }

  .alert {
    padding: 8px 0px;
    line-height: 18px;
    font-size: 12px;
    color: var(--red-200);
  }
</style>
