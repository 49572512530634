<script>
  import styles from "styleguide/ConfirmationNewInvitation.json";

  import { t } from "stores/i18n.js";
  import { invitation } from "stores/confirmation/confirmation_new.js";

  import { ConfirmationNewBlock } from "apps/confirmationNew";
  import { Checkbox, Toast } from "components";

  export let active;
</script>

<ConfirmationNewBlock
  {active}
  edit={() => {
    active = true;
  }}
  title={$t("confirmation_new_invitation.title")}
>
  <div data-component="ConfirmationNewInvitation">
    <Checkbox
      toggled={$invitation}
      toggle={(value) => {
        invitation.set(value);
      }}
      label={$t("confirmation_new_invitation.label")}
    />
  </div>

  <div class={styles["toast-margin"]}>
    <Toast type="info" title={$t("confirmation_new_invitation.infobox_title")}>
      <ul>
        <li class={styles.item}>
          {$t("confirmation_new_invitation.infobox_item1")}
        </li>
        <li class={styles.item}>
          {$t("confirmation_new_invitation.infobox_item2")}
        </li>
        <li class={styles.item}>
          {$t("confirmation_new_invitation.infobox_item3")}
        </li>
      </ul>
    </Toast>
  </div>
</ConfirmationNewBlock>

<style lang="scss">
  .input {
    margin-bottom: 20px;
  }

  ul {
    display: block;
    list-style: disc;
  }

  .toast-margin {
    margin: 20px 0;
  }
</style>
