<script>
  import styles from "styleguide/ConfirmationSidebarComments.json";
  import { getContext, onMount } from "svelte";

  import { user, isUserTeamUser } from "stores/user.js";
  import { isAccountFeature } from "stores/account";
  import { t } from "stores/i18n.js";
  import { item } from "stores/confirmation/confirmation_item.js";

  import CommentsApi from "apis/comments/comments.js";
  import { isReviewed } from "lib/helpers.js";

  import { Boundary, Button, Comment, TextEditor } from "components";

  let comments = getContext("comments");
  let onPostComment = getContext("onPostComment");
  let parent = getContext("parent");
  let parent_type = getContext("parent_type");
  let comments_visible = getContext("comments_visible");
  let getComments = getContext("getComments");
  let clientUsers = [];

  $: notRead = !$comments.every((item) => isReviewed(item, $user.role));

  function onMarkAsRead() {
    CommentsApi.markAsRead({
      params: {
        parent_type,
        parent_id: $parent.permalink,
      },
      success: getComments,
    });
  }

  function getClientsByType(clients) {
    return clients.reduce((acc, item) => {
      const { confirmation_type } = item;
      acc[confirmation_type] = [...(acc[confirmation_type] || []), item];
      return acc;
    }, {});
  }

  onMount(() => {
    if (comments_visible) {
      const type = $item.confirmation_type;
      const clientsByTypes = getClientsByType($item.confirmation_client_users);

      clientUsers = clientsByTypes[type]?.map((item) => item.client_user) || [];
    }
  });
</script>

<Boundary>
  <div data-component="ConfirmationSidebarComments" class={styles.wrapper}>
    {#if $isUserTeamUser}
      <div class={styles.block}>
        <div class={styles.note}>
          <span>{$t("audit_sidebar_comments.note")}</span>
          {#if comments_visible}
            {$t("audit_sidebar_comments.comments_visible")}
          {:else}
            {$t("audit_sidebar_comments.comments_visible_no")}
          {/if}
        </div>
      </div>
    {/if}
    <div class={styles.block}>
      <TextEditor
        allowMentions={$isAccountFeature("mentions")}
        style="margin toolbar-full toolbar-top"
        sidebar={true}
        under={true}
        border={false}
        parent_type="confirmation"
        parent_id={$parent.confirmation_permalink
          ? $parent.confirmation_permalink
          : $parent.permalink}
        {clientUsers}
        {onPostComment}
      />
    </div>
    <div class={styles.comments}>
      {#if notRead}
        <div class={styles.mark}>
          <Button click={onMarkAsRead} style="primary-text small"
            >{$t("audit_sidebar_comments.comments_mark_as_read")}</Button
          >
        </div>
      {/if}

      {#each $comments as comment}
        {#if !comment.is_deleted}
          <div class={styles.item}>
            <Comment
              {...comment}
              sidebar={true}
              picture={false}
              {parent_type}
              parent_id={$parent.permalink}
              onDeleteSuccess={getComments}
            />
          </div>
        {/if}
      {:else}
        <div class={styles.noyet}>
          {$t("audit_sidebar_comments.no_comments")}
        </div>
      {/each}
    </div>
  </div>
</Boundary>

<style lang="scss">
  .comments {
    height: 380px;
    overflow-y: auto;
  }

  .noyet {
    margin-top: 30px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: var(--primary-400);
  }

  .item {
    padding: 0 20px;
    border-bottom: solid 1px var(--primary-050);

    &:last-child {
      border-bottom: none;
    }
  }

  .block {
    padding: 4px 10px;
    border-bottom: solid 1px var(--primary-050);
  }

  .note {
    margin: 10px 8px;

    span {
      font-weight: 600;
      color: var(--orange);
    }
  }

  .mark {
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
    text-align: center;
  }
</style>
