<script>
  import styles from "styleguide/InputWrapper.json";
  import { t } from "stores/i18n.js";
  import { sanitizeRelaxed } from "lib/sanitize.js";

  export let label;
  export let optional;
  export let errors;
  export let footnotes;
  export let reset;
  export let style = "";

  $: calculatedStyles = function () {
    let s = style.split(" ");
    return s.map((x) => styles[x]).join(" ");
  };
</script>

<div data-component="InputWrapper" class={styles.wrapper}>
  <label class="{styles.wrapper} {calculatedStyles()} ">
    <div class={styles.top}>
      {#if label}
        <span class={styles.label}>
          <span class={styles["label-text"]}
            >{@html sanitizeRelaxed(label)}</span
          >
          {#if optional}
            <span class={styles.optional}>({$t("text_input.optional")})</span>
          {/if}
        </span>
      {/if}

      {#if reset}
        <!-- <Button style="ghost medium" click={reset}>{$t('text_input.reset')}</Button> -->
        <div class={styles.reset} on:click={reset}>
          {$t("text_input.reset")}
        </div>
      {/if}
    </div>

    <slot />
  </label>

  {#if errors && errors.length}
    <div class={styles.errors}>
      {#each errors as error}
        {#if error}
          <small class={styles.error}>{error}</small>
        {/if}
      {/each}
    </div>
  {/if}

  {#if footnotes}
    <div class={styles.footnotes}>
      {#each footnotes as footnote}
        {#if footnote}
          <small class={styles.footnote}>{footnote}</small>
        {/if}
      {/each}
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    flex: 1;
    width: 100%;
  }

  .label {
    display: inline-block;
    margin: 0 5px 5px 0;
    color: var(--primary-500);
  }

  .label-text {
    .bold & {
      font-weight: 600;
    }
  }

  .error {
    color: var(--red-200);
  }

  .optional {
    color: var(--primary-300);
  }

  .footnote {
    color: var(--primary-300);
  }

  .error,
  .footnote {
    display: block;
    font-size: 12px;
    line-height: 18px;
  }

  .errors,
  .footnotes {
    margin-top: 6px;
  }

  .reset {
    display: inline-block;
    color: var(--blue-200);
    cursor: pointer;
  }
</style>
