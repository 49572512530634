<script>
  import styles from "styleguide/QuickshareIndex.json";
  import { onMount, getContext } from "svelte";

  import { addedFiles } from "stores/quickshare.js";
  import { t } from "stores/i18n.js";

  import { Dropzone, TextInput } from "components";
  import { QuickshareFolder, QuickshareHeader } from "components/quickshare";

  const api = getContext("oksend");
  let uploads = [];
  let filterQuery = "";

  $: filteredUploads = filterUploads(filterQuery, uploads);

  function filterUploads(query, uploads) {
    if (query.length == 0) {
      return uploads;
    } else {
      return uploads.filter(
        (u) => u.name.toLowerCase().indexOf(query.toLowerCase()) !== -1,
      );
    }
  }

  function addFiles(files) {
    addedFiles.set(Array.from(files));
  }

  function onUploadsReceived(response) {
    uploads = response;
  }

  function getUploads() {
    api.uploads.index({
      success: onUploadsReceived,
      error: () => console.log("ERROR"),
    });
  }

  onMount(async () => {
    getUploads();
  });
</script>

<QuickshareHeader>Quick Share</QuickshareHeader>

<div data-component="QuickshareIndex" class={styles.wrapper}>
  <div class={styles.dropzone}>
    <Dropzone onFiles={addFiles} />
  </div>
  <div class={styles.uploads}>
    <div class={styles.search}>
      <TextInput
        bind:value={filterQuery}
        placeholder={$t("quickshare_index.search")}
      />
    </div>
    {#each filteredUploads as upload (upload)}
      <QuickshareFolder {upload} />
    {/each}
    {#if filteredUploads.length == 0}
      <div class={styles.empty}>{$t("quickshare_index.empty")}</div>
    {/if}
  </div>
</div>

<style lang="scss">
  .header {
    border-bottom: 1px solid var(--primary-050);
    padding: 10px 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
  }

  .search {
    padding: 0 20px 20px 20px;
  }

  .empty {
    text-align: center;
    flex: 1;
    color: var(--primary-300);
    padding: 20px;
  }

  .dropzone {
    height: 170px;
    padding: 20px;
  }

  .uploads {
    flex: 1;
  }
</style>
