<script>
  import { onMount, onDestroy } from "svelte";
  import clsx from "clsx";
  import { t } from "stores/i18n.js";
  import { createPopperActions } from "svelte-popperjs";

  import { iconPath } from "lib/helpers.js";

  import { InputWrapper, LegacyButton as Button } from "components";
  import styles from "styleguide/Search.json";

  export let value = "";
  export let icon = true;
  export let placeholder = undefined;
  export let lock = false;
  export let disabled = lock;
  export let style = "";
  export let select = undefined;
  export let getOrigin = undefined;
  export let getModified = undefined;
  export let arrows = false;
  export let enter = undefined;
  export let click = undefined;
  export let label;
  export let optional;
  export let footnotes;
  export let errors;
  export let clear = select || getOrigin || getModified;
  export let debounceTime = 500;
  export let searchApi = undefined;
  export let searchApiError = undefined;
  export let searchApiParams = {};
  export let modifySearchResults;
  export let enterIcon = undefined;
  export let enterText = undefined;
  export let mention = false;
  export let start = false;
  export let searchList = undefined;
  export let useSameWidth = true;
  export let placement = "auto";

  const [popperRef, popperContent] = createPopperActions({
    strategy: "fixed",
    placement: placement,
  });

  const sameWidth = {
    name: "sameWidth",
    enabled: true,
    phase: "beforeWrite",
    requires: ["computeStyles"],
    fn: ({ state }) => {
      state.styles.popper.width = `${state.rects.reference.width}px`;
    },
    effect: ({ state }) => {
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    },
  };

  const extraOpts = {
    placement: "auto",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: ({ placement }) => {
            if (placement === "bottom") {
              return [0, 8];
            } else if (placement === "top") {
              return [0, 8];
            } else {
              return [0, 0];
            }
          },
        },
      },
      { name: "flip", options: { allowedAutoPlacements: ["top", "bottom"] } },
    ],
  };
  if (useSameWidth) {
    extraOpts.modifiers.push(sameWidth);
  }

  let userSelectedValue = false;
  let active;
  let timer;
  let input;
  let searchResults = [];
  let searchResultsModified = [];
  let currentIndex = 0;
  let highlightButtons = {};
  let controls;
  let itemsBlock;
  let showCurrentIndex = true;

  $: show =
    value &&
    (!mention || searchResultsModified.length) &&
    userSelectedValue != value;
  $: big = itemsBlock && itemsBlock.clientWidth > 520;

  $: if ((value || start) && !lock && userSelectedValue != value) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getSearchData(value);
    }, debounceTime);
  }

  function init() {
    document.addEventListener("click", close);

    return {
      destroy() {
        document.removeEventListener("click", close);
      },
    };
  }

  function getSearchData(search = "") {
    if (searchApi) {
      const options = {
        params: {
          search,
          ...searchApiParams,
        },
        success: (data) => {
          searchResults = data;
          searchResultsModified = modifySearchResults(data);
        },
        error: (error) => {
          if (searchApiError) searchApiError(error);
          searchResults = [];
          searchResultsModified = [];
        },
      };

      searchApi.search(options);
    } else if (search === "#") {
      searchResultsModified = searchList;
    } else if (searchList) {
      searchResultsModified = searchList.filter((item) =>
        item.label.toLowerCase().includes(search.toLowerCase()),
      );
    }
  }

  async function onSelectItem(selectedKey, selectedValue) {
    if (select) select(selectedKey, selectedValue);
    if (getOrigin) getOrigin(selectedKey, searchResults);
    if (getModified) getModified(selectedKey, searchResultsModified);
    if (clear) {
      value = "";
      userSelectedValue = false;
    } else {
      value = selectedValue;
    }
    userSelectedValue = value;

    setTimeout(() => {
      show = false;
    }, 100);
  }

  function onEnter() {
    if (enter) {
      enter(value);
      if (clear) {
        value = "";
        userSelectedValue = false;
      }
    }

    setTimeout(() => {
      show = false;
    }, 100);
  }

  function onClear() {
    value = "";
  }

  function onKeydown(event) {
    if (mention && !show) return;

    if (event.code === "Enter") {
      if (show) {
        highlightButtons.Enter = true;
        setTimeout(() => {
          if (searchResultsModified && searchResultsModified[currentIndex]) {
            let { value, label } = searchResultsModified[currentIndex];
            onSelectItem && onSelectItem(value, label);
          }
          if (clear) {
            value = "";
          }
          highlightButtons.Enter = false;
          show = false;
        }, 300);
      } else if ((!show && !value) || !enter) {
        show = true;
      } else if (enter) {
        enter(value);
        if (clear) {
          value = "";
        }
      }
      event.preventDefault();
    } else if (event.code === "ArrowDown") {
      highlightButtons.ArrowDown = true;
      currentIndex < searchResultsModified.length - 1
        ? currentIndex++
        : (currentIndex = 0);
      showCurrentIndex = true;
      setTimeout(() => {
        highlightButtons.ArrowDown = false;
      }, 300);
      event.preventDefault();
    } else if (event.code === "ArrowUp") {
      highlightButtons.ArrowUp = true;
      currentIndex > 0
        ? currentIndex--
        : (currentIndex = searchResultsModified.length - 1);
      showCurrentIndex = true;
      setTimeout(() => {
        highlightButtons.ArrowUp = false;
      }, 300);
      event.preventDefault();
    } else if (event.code === "Escape") {
      highlightButtons.Escape = true;
      setTimeout(() => {
        value = "";
        show = false;
        highlightButtons.Escape = false;
      }, 300);
      event.preventDefault();
    }
  }

  function onFocus() {
    active = true;
  }

  function onBlur(event) {
    setTimeout(() => {
      show = false;
      active = false;
    }, 200);
  }

  function onClick() {
    if (!lock) {
      if (click) click(value);
      if (value) show = true;
    }
  }

  function close(event) {
    if (!event.target.closest(`.${styles.search}`)) {
      show = false;
    }
  }

  onMount(async () => {
    if (mention) {
      document.addEventListener("keydown", onKeydown);
    }
  });

  onDestroy(() => {
    document.removeEventListener("keydown", onKeydown);
  });
</script>

<InputWrapper {label} {optional} {footnotes} {errors}>
  <div
    data-component="Search"
    use:init
    use:popperRef
    class={clsx(
      styles.search,
      style.split(" ").map((x) => styles[x]),
      { [styles["lock"]]: lock },
      { [styles["icon"]]: icon },
      { [styles["show"]]: show },
      { [styles["active"]]: active },
      { [styles["arrows"]]: arrows },
      { [styles["mention"]]: mention },
      { [styles["error"]]: errors && errors.length },
    )}
    on:click={onClick}
  >
    <label class={styles["input-wrapper"]}>
      {#if icon}
        <img src={iconPath("search")} alt="search" />
      {/if}
      <input
        data-keyboard={show}
        on:keydown={onKeydown}
        on:focus={onFocus}
        on:blur={onBlur}
        bind:value
        bind:this={input}
        {placeholder}
        {disabled}
        type="text"
      />
      {#if lock}
        <img src={iconPath("grey_lock.svg")} alt="" />
      {:else if (enterIcon || enterText) && show && searchResultsModified.length === 0}
        <div class={styles.enter}>
          <Button
            click={onEnter}
            icon={enterIcon}
            style={`small ${icon ? "icon" : ""} ${!enterText ? "icon-only" : ""}`}
          >
            {enterText ? enterText : ""}
          </Button>
        </div>
      {/if}

      {#if value && !lock && !enterIcon}
        <div class={styles.clear}>
          <Button
            click={onClear}
            icon="grey_close"
            style={"small icon icon-only hover grey"}
          />
        </div>
      {/if}
    </label>
    {#if show}
      <div
        use:popperContent={extraOpts}
        class={styles.items}
        bind:this={itemsBlock}
        on:mouseover={() => {
          showCurrentIndex = false;
        }}
        on:mouseout={() => {
          showCurrentIndex = true;
        }}
      >
        {#if big}
          <div class={styles.controls} bind:this={controls}>
            <div class={styles["controls-item"]}>
              <div class={styles.control}>
                <Button icon="right-tab" style={"border icon-only medium"} />
              </div>

              <div class={styles["controls-text"]}>{$t("search.navigate")}</div>
            </div>

            <div class={styles["controls-item"]}>
              <div class={styles.control}>
                <Button
                  icon="up"
                  active={highlightButtons.ArrowUp}
                  style={"border icon-only medium"}
                />
              </div>

              <div class={styles.control}>
                <Button
                  icon="down"
                  active={highlightButtons.ArrowDown}
                  style={"border icon-only medium"}
                />
              </div>

              <div class={styles["controls-text"]}>{$t("search.or")}</div>
            </div>

            <div class={styles["controls-item"]}>
              <div class={styles.control}>
                <Button
                  icon="enter"
                  active={highlightButtons.Enter}
                  style={"border icon-only medium"}
                />
              </div>

              <div class={styles["controls-text"]}>{$t("search.select")}</div>
            </div>

            <div class={styles["controls-item"]}>
              <div class={styles.control}>
                <Button active={highlightButtons.Escape} style={"border medium"}
                  >Esc</Button
                >
              </div>

              <div class={styles["controls-text"]}>{$t("search.cancel")}</div>
            </div>
          </div>
        {/if}

        {#each searchResultsModified as { value, label }, index (value)}
          <div
            class={`${styles.item} ${
              currentIndex === index && showCurrentIndex ? styles.active : ""
            }`}
            on:mouseout={() => {
              currentIndex = index;
            }}
            on:click={() => {
              onSelectItem(value, label);
            }}
          >
            {#if arrows}
              <img
                class={styles["item-arrow"]}
                src="/assets/svg_icons/right.svg"
                alt="right arrow"
              />
            {/if}
            <div class={styles.label}>{label}</div>
            {#if big || mention}
              <div class={styles.enter}>
                <Button icon={"enter"} style={"small icon"}>Enter</Button>
              </div>
            {/if}
          </div>
        {:else}
          {#if $$slots.empty}
            <slot name="empty" />
          {:else}
            <div class={styles.item}>{$t("search.no_results")}</div>
          {/if}
        {/each}
      </div>
    {/if}
  </div>
</InputWrapper>

<style lang="scss">
  .search {
    flex: 1;
    min-width: 0;
    //position: relative;
    padding: 7px 12px;
    background: #fff;
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-050);
    //z-index: 3;

    &.active,
    &:hover,
    &.show {
      &:not(.lock) {
        border: 1px solid var(--blue);
        box-shadow: 0 0 0 3px rgba(70, 132, 230, 0.1);
      }
    }

    &.mention {
      padding: 0;
      min-width: 300px;
      border: none !important;
      box-shadow: none !important;
    }

    &.mention-big {
      min-width: 380px;
    }

    &.big {
      padding: 12px 15px;
    }

    &.top-side {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.bottom-side {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.left-side {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.right-side {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.error {
      border: 1px solid var(--red-100);
    }

    &.no-right-border {
      border-right: none;
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;

    .mention & {
      display: none;
    }
  }

  input {
    display: block;
    width: 100%;
    height: 24px;
    outline: none;
    border: none;
    padding: 0;
    background-color: #fff;
    line-height: 24px;

    .icon & {
      margin-left: 15px;
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .items {
    position: absolute;
    right: 0;
    left: -1px;
    display: flex;
    flex-direction: column;
    width: calc(100% + 2px);
    min-width: 0;
    color: var(--blue-200);
    border: 1px solid var(--primary-050);
    background: #fff;
    box-shadow: 0 3px 8px 0 rgba(41, 49, 61, 0.1);
    border-radius: var(--border-radius);
    padding: 4px;
    z-index: 3;

    .search:not(.top) & {
      top: 50px;
    }

    .big:not(.top) & {
      top: 60px;
    }

    .search.top & {
      bottom: 50px;
    }

    .big.search.top & {
      bottom: 60px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: var(--border-radius);
    cursor: pointer;
    position: relative;

    .enter {
      display: none;
    }

    &:hover,
    &.active {
      background: var(--blue-010);

      .enter {
        display: flex;
        padding: 0 4px 0 16px;
        background: linear-gradient(
          to right,
          transparent 0%,
          var(--blue-010) 20px,
          var(--blue-010) 100%
        );
      }
    }

    .arrows & {
      color: var(--blue-200);

      &:hover {
        background-color: var(--blue-050);
      }
    }
  }

  .item-arrow {
    margin-right: 10px;
  }

  .label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .enter {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .clear {
    display: flex;
    align-items: center;
  }

  .controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 10px;
    margin: 0 -4px 4px;
    border-bottom: 1px solid var(--primary-050);
    display: none;
  }

  .controls-item {
    display: flex;
    align-items: center;
  }

  .control,
  .controls-text {
    margin-right: 10px;
  }

  .controls-text {
    color: var(--primary-500);

    .controls.small & {
      display: none;
    }
  }
</style>
