<script>
  import { Modal } from "components/modals";
  import { ProductSelectionOverview } from "components/productSelection";
  import { t } from "stores/i18n.js";
  import styles from "styleguide/ProductSelectionModal.json";

  export let showModal;
  export let projectName;
  export let projectPermalink;
  export let onHide;
  export let showConfirmationModal;
  export let showSandboxModal;
  export let showNewAuditModal;
</script>

<Modal
  isWide
  title={$t("product_selection_modal.options_modal_footnote")}
  bind:shown={showModal}
  {onHide}
  close={true}
>
  <div
    data-component="ProductSelectionModal"
    class={styles["project-options-modal"]}
  >
    <ProductSelectionOverview
      isModalView
      {onHide}
      bind:showConfirmationModal
      bind:showSandboxModal
      bind:showNewAuditModal
      {projectPermalink}
      {projectName}
    />
  </div>
</Modal>

<style>
  .project-options-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-bottom: 14px;
    border-radius: 8px;
    max-width: 1000px;
  }
</style>
