<script>
  import {
    DashboardProjectControl,
    DashboardProjectItem,
  } from "apps/dashboard";
  import {
    DashboardAuditNewModal,
    Modal,
    ProductSelectionModal,
    SandboxLoadingModal,
  } from "components/modals";
  import { ProductSelectionOverview } from "components/productSelection";
  import {
    newAuditProjectId,
    newAuditProjectName,
  } from "stores/dashboard/audit_new.js";
  import { project, couldSaveUsers } from "stores/dashboard/project.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { user } from "stores/user.js";

  import { ConfirmationNew } from "apps";

  import { Boundary, Box, Button, Switch, UserPictures } from "components";
  import styles from "styleguide/DashboardProjectShowContent.json";

  let dashboardProjectControl;
  let manageUsers = false;
  let addConformation = false;
  let items = [];
  let itemsToShow = [];
  let hideArchived =
    localStorage.getItem("project--config-archived") === "true";
  let showConfirmationModal = false;
  let showSandboxModal = false;
  let showNewAuditModal = false;

  $: itemsToShow = filterItems(items, hideArchived);
  $: showProductOverviewModal =
    addConformation &&
    !showConfirmationModal &&
    !showSandboxModal &&
    !showNewAuditModal;
  $: isValid = $couldSaveUsers;

  project.subscribe((value) => {
    items = value.items ? value.items : [];
  });

  function onArchivedToggle(toggled) {
    hideArchived = !toggled;
    localStorage.setItem("project--config-archived", hideArchived);
  }

  function filterItems(items, filter) {
    if (filter) {
      return items.filter((item) => !item.archived);
    } else {
      return items;
    }
  }

  function onSandboxModalHide() {
    addConformation = true;
    showSandboxModal = false;
  }

  function onCloseNewAuditModal() {
    showNewAuditModal = false;
    addConformation = true;
  }
</script>

<Boundary>
  <div data-component="DashboardProjectShowContent" class={styles.wrapper}>
    {#if $user.role !== "client"}
      <Box>
        <div class={styles.header}>
          <div class={styles["header-title"]}>
            {$t("dashboard_project.workbook_users")}
          </div>
          {#if $can("add_user", "project")}
            <div class={styles["button"]}>
              <Button
                click={() => {
                  manageUsers = true;
                }}
                style="primary-text small"
              >
                {$t("dashboard_project.manage_users")}
              </Button>
            </div>
          {/if}
        </div>

        {#if $project.team_users}
          <div class={styles.users}>
            <UserPictures
              style="separate"
              users={$project.team_users}
              isAvatarClickable={false}
            />
          </div>
        {/if}
      </Box>
    {/if}

    {#if items.length}
      <Box>
        <div class={styles.archived}>
          <div class={styles["switch-toggle"]}>
            <Switch toggled={!hideArchived} toggle={onArchivedToggle} />
            <div class={styles["archived-label"]}>
              {$t("dashboard_project.show_archived")}
            </div>
          </div>

          {#if $can("create", "review") || $can("create", "confirmation") || $can("create", "audit")}
            <div class={styles["button"]}>
              <Button
                click={() => {
                  addConformation = true;
                }}
                style="primary-tonal small"
              >
                {$t("dashboard_project.workbook_new")}
              </Button>
            </div>
          {/if}
        </div>

        <div class={styles.samples}>
          {#each itemsToShow as item (item.permalink)}
            <div class={styles.container}>
              <DashboardProjectItem {...item} />
            </div>
          {/each}
        </div>
      </Box>
    {/if}

    {#if !itemsToShow.length && ($can("create", "review") || $can("create", "confirmation") || $can("create", "audit"))}
      <ProductSelectionOverview
        bind:showConfirmationModal
        bind:showSandboxModal
        bind:showNewAuditModal
        projectPermalink={$project.permalink}
        projectName={$project.name}
      />
    {/if}

    <ConfirmationNew
      projectId={$project.permalink}
      bind:shown={showConfirmationModal}
    />

    <Modal
      bind:shown={manageUsers}
      title={$t("dashboard_project.manage_users")}
      onHide={() => {
        manageUsers = false;
      }}
    >
      <DashboardProjectControl
        type="manage_users"
        {...$project}
        bind:showModal={manageUsers}
        bind:this={dashboardProjectControl}
      />
      <svelte:fragment slot="bottom">
        {#if !!dashboardProjectControl}
          <Button
            style={"primary"}
            click={dashboardProjectControl.onSubmit}
            disabled={!isValid}
          >
            {$t("dashboard_new_project.save_changes")}
          </Button>
        {/if}
      </svelte:fragment>
    </Modal>

    <SandboxLoadingModal
      bind:showModal={showSandboxModal}
      onHide={onSandboxModalHide}
    />

    {#if itemsToShow.length}
      <ProductSelectionModal
        bind:showModal={showProductOverviewModal}
        bind:showConfirmationModal
        bind:showSandboxModal
        bind:showNewAuditModal
        projectName={$project.name}
        projectPermalink={$project.permalink}
        onHide={() => {
          addConformation = false;
        }}
      />
    {/if}

    <DashboardAuditNewModal
      bind:shown={showNewAuditModal}
      projectId={$newAuditProjectId}
      projectName={$newAuditProjectName}
      onCancel={() => {
        onCloseNewAuditModal();
        newAuditProjectId.set(null);
      }}
    />
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .container {
    padding: 8px 20px;
    border-top: solid 1px var(--primary-050);
  }

  .header {
    display: flex;
    align-items: stretch;
  }
  .header-title {
    flex: 1;
    color: var(--primary-500);
    padding: 14px 20px;
    font-weight: 600;
  }

  .button {
    display: flex;
    align-items: center;
    margin: 0 18px;
  }

  .users {
    display: flex;
  }
  .users {
    padding: 15px 20px;
    border-top: 1px solid var(--primary-050);
  }

  .archived {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .switch-toggle {
    display: flex;
    align-items: center;
    margin: 13px 20px;
  }

  .archived-label {
    margin-left: 12px;
    color: var(--primary-500);
    font-size: 14px;
    font-weight: 400;
  }
</style>
