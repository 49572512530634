<script>
  import { t } from "stores/i18n.js";
  import { usePreviousLetterhead } from "stores/confirmation/confirmation_new.js";

  import { Checkbox } from "components";
  import { ConfirmationNewBlock } from "apps/confirmationNew";

  export let active;
</script>

<ConfirmationNewBlock
  {active}
  edit={() => {
    active = true;
  }}
  title={$t("confirmation_new_letterhead.title")}
>
  <div data-component="ConfirmationNewLetterhead">
    <Checkbox
      toggled={$usePreviousLetterhead}
      toggle={(value) => {
        usePreviousLetterhead.set(value);
      }}
      label={$t("confirmation_new_letterhead.label")}
    />
  </div>
</ConfirmationNewBlock>

<style lang="scss">
  .input {
    margin-bottom: 20px;
  }
</style>
